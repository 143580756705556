/* eslint-disable no-restricted-syntax */

import {currentUserChangeDispatcher} from './user'
import {BACKEND} from '~/config'
import {handleHttpError} from '~/services/error'
import {http} from '~/utils/http'

const audioElement = document.createElement('audio')

export function synthesize(
    voiceId: string,
    text: string,
    speed: number,
    language = 'auto',
    sampleRate?: number,
    emotion?: string,
) {
    return http.post<Blob>(`${BACKEND.api}/tts/synthesize`, {
        voice: voiceId,
        text,
        language,
        speed,
        sample_rate: sampleRate,
        emotion,
    })
        .catch(handleHttpError)
}

export function synthesizeDemo(
    voiceId: string,
    text: string,
    speed: number,
    language = 'auto',
    emotion?: string,
) {
    return http.post<Blob>(`${BACKEND.api}/demo/tts/synthesize`, {voice: voiceId, text, language, speed, emotion})
        .catch(handleHttpError)
}

type Emotion = {
    name: string
    display_name: string
}

export type Voice = {
    id: string
    name: string
    version: string
    languages: string[]
    emotions?: Emotion[]
}

let voiceCache: Promise<Voice[]> | undefined

currentUserChangeDispatcher.subscribe(() => voiceCache = undefined)

export function getVoices() {
    return voiceCache ||= http.get<{data: Voice[]}>(`${BACKEND.api}/tts/voices`)
        .then(({data}) => data)
        .catch(error => {
            voiceCache = undefined
            throw error
        })
        .catch(handleHttpError)
}

// TODO убрать, когда бэкенд начнет возвращать лимит
export const demoSynthesisCharacterLimit = 500

let demoVoiceCache: Promise<Voice[]> | undefined

export function getDemoVoices() {
    return demoVoiceCache ||= http.get<{data: Voice[]}>(`${BACKEND.api}/demo/tts/voices`)
        .then(({data}) => data)
        .catch(error => {
            demoVoiceCache = undefined
            throw error
        })
        .catch(handleHttpError)
}

export function playAudio(src: string) {
    audioElement.src = src

    return audioElement.play()
}

export function pauseAudio() {
    audioElement.pause()
}

export function resumeAudio() {
    audioElement.play()
}

export function subscribeOnEvents(handlers: Partial<Record<keyof HTMLMediaElementEventMap, () => void>>) {
    const items = Object.entries(handlers)

    items.forEach(([key, handler]) => audioElement.addEventListener(key, handler))

    return () => items.forEach(([key, handler]) => audioElement.removeEventListener(key, handler))
}

export type SoundStatus = 'playing' | 'paused' | 'stopped'

export const exampleTexts: {[Key: string]: string | undefined, default: string} = {
    default: [
        'Приветствуем на демонстрации технологии синтеза речи.\n',
        '\n',
        'Современные алгоритмы искусственного интеллекта преобразуют текст ',
        'в плавную и естественную человеческую речь. Оцените ',
        'чёткость произношения, интонацию и отсутствие механических пауз.\n',
        '\n',
        'Настраивайте голос, скорость и эмоциональную окраску, ',
        'делая звучание максимально комфортным для восприятия. You can also choose the ',
        'synthesis language, but so far only English and Kazakh are available. Choose a voice ',
        'and customize it to suit any of your tasks!',
    ]
        .join(''),
    kk: [
        'Сөйлеу синтезі технологиясының көрсетіліміне қош келдіңіз.\n',
        '\n',
        'Қазіргі заманғы жасанды интеллект алгоритмдері мәтінді адамның тегіс және табиғи ',
        'сөйлеуіне айналдырады. Айтылымның анықтығын, интонациясын және механикалық ',
        'үзілістердің жоқтығын бағалаңыз.\n',
        '\n',
        'Дауысты, жылдамдықты және эмоционалды бояуды реттеңіз, дыбысты қабылдауға мүмкіндігінше ',
        'ыңғайлы етіңіз. You can also choose the synthesis language, but so far only English and ',
        'Russian are available. Choose a voice and customize it to suit your needs!',
    ]
        .join(''),
}
