import clsx from 'clsx'
import {Button} from 'primereact/button'
import {Dropdown, type DropdownProps} from 'primereact/dropdown'
import {InputTextarea} from 'primereact/inputtextarea'
import {useEffect, useMemo, useState} from 'react'

import OverallProgress from '../OverallProgress'
import Progress from '../Progress'
import {trl, trlCount, trlObject} from '~/services/intl'
import {showErrorPopup} from '~/services/popup'
import {
    subscribeOnEvents,
    getDemoVoices,
    pauseAudio,
    playAudio,
    resumeAudio,
    synthesizeDemo,
    demoSynthesisCharacterLimit,
    exampleTexts,
} from '~/services/synthesis'
import {useObjectUrl} from '~/utils/useObjectUrl'

import type {SoundStatus, Voice} from '~/services/synthesis'

const getSpeeds = () => Object.entries(trlObject('_synthesis.speeds'))
    .map(([value, label]) => ({
        value: +value,
        label,
    }))
    .sort((speed1, speed2) => speed2.value - speed1.value)

const initialEmptyVoices: Voice[] = []

export default function() {
    const {start, resume, pause, synthesizeSpeech} = trlObject('_landing.boxes')['4'].blocks.speechSynthesis

    const [audio, setAudio] = useObjectUrl()
    const [soundStatus, setSoundStatus] = useState<SoundStatus>('stopped')
    const [text, setText] = useState('')
    const [voices, setVoices] = useState(initialEmptyVoices)
    const [language, setLanguage] = useState('')
    const [voiceId, setVoiceId] = useState('')
    const [emotionId, setEmotionId] = useState('')
    const speeds = getSpeeds()
    const [speed, setSpeed] = useState(1)
    const [ready, setReady] = useState(true)

    useEffect(
        () => {
            getDemoVoices()
                .then(voices => {
                    setVoices(voices)
                    setVoiceId(voices[0].id)
                    setText(exampleTexts[voices[0].languages[0] || ''] || exampleTexts.default)
                })
                .catch(showErrorPopup)
        },
        [],
    )

    useEffect(() => subscribeOnEvents({
        ended: () => setSoundStatus('stopped'),
        play: () => setSoundStatus('playing'),
        pause: () => setSoundStatus(audio ? 'paused' : 'stopped'),
    }), [audio])

    const updateText = (value: string) => {
        if (value != text)
            setAudio(undefined)
        setText(value)
    }

    const handleClick = () => {
        switch (soundStatus) {
        case 'stopped':
            return audio ? playAudio(audio) : synthesize()
        case 'playing':
            return pauseAudio()
        case 'paused':
            return resumeAudio()
        }
    }

    useEffect(
        () => {
            if (audio)
                playAudio(audio)
            else
                setSoundStatus('stopped')

            return pauseAudio
        },
        [audio]
    )

    const synthesize = () => {
        setReady(false)

        synthesizeDemo(voiceId, text, speed, language, emotionId || undefined)
            .then(setAudio)
            .catch(showErrorPopup)
            .finally(() => setReady(true))
    }

    const getButtonText = () => {
        if (soundStatus == 'stopped')
            return audio ? start : synthesizeSpeech

        return soundStatus == 'paused' ? resume : pause
    }

    const exceededLimit = text.length > demoSynthesisCharacterLimit

    const selectedVoice = voices.find(voice => voice.id == voiceId)

    const languageOptions = useMemo(
        () => {
            const languageMap: {[Key in string]?: string} = trlObject('_languages')

            return [
                {
                    value: 'auto',
                    label: trl('_languages.autoselect'),
                },
                ...(selectedVoice?.languages || [])
                    .map(language => ({
                        value: language,
                        label: languageMap[language] || language,
                    })),
            ]
        },
        [selectedVoice],
    )

    const emotionOptions = useMemo(
        () => (selectedVoice?.emotions || [])
            .map(emotion => ({
                value: emotion.name,
                label: emotion.display_name,
            })),
        [selectedVoice],
    )

    useEffect(
        () => {
            if (!languageOptions.some(({value}) => value == language))
                setLanguage(languageOptions[0].value)
        },
        [language, languageOptions],
    )

    useEffect(
        () => {
            if (!emotionOptions.some(({value}) => value == emotionId))
                setEmotionId(emotionOptions[0]?.value || '')
        },
        [emotionId, emotionOptions],
    )

    const renderSelectedVoice = (option: Voice | undefined, props: DropdownProps) => {
        if (option)
            return (
                <div className='row row_gap-2'>
                    <div className={`flag flag-${option.languages[0]}`}/>
                    <div>{option.name}</div>
                </div>
            )

        return <span>{props.placeholder}</span>
    }

    const renderVoiceOption = (option: Voice) =>
        <div className='row row_gap-2'>
            <div className={`flag flag-${option.languages[0]}`}/>
            <div>{option.name}</div>
        </div>

    if (voices == initialEmptyVoices)
        return <div className='landing-recognition row row_center row_gap-6'>
            <OverallProgress/>
        </div>

    const exampleText = exampleTexts[selectedVoice?.languages[0] || ''] || exampleTexts.default

    return (
        <div className='landing-recognition row row_col row row_gap-6'>
            <div className='landing-recognition__content row row_nowrap row_top'>
                <div className='row row_col landing-recognition__textarea-wrapper'>
                    <InputTextarea
                        className={clsx('landing-recognition__textarea', exceededLimit && 'p-invalid')}
                        value={text}
                        onChange={({target}) => updateText(target.value)}
                        readOnly={!ready}
                    />
                    <button
                        className='text text_small text_grey landing-recognition__insert-text'
                        onClick={() => {
                            setText(exampleText)
                            setAudio(undefined)
                        }}
                        hidden={text == exampleText}
                        disabled={!ready || !selectedVoice}
                    >
                        {trl('Пример')}
                    </button>
                    <div
                        className={clsx(
                            'text text_small text_grey landing-recognition__synthesis-hint',
                            exceededLimit && 'text_assertive'
                        )}
                    >
                        {exceededLimit
                            ? trlCount('Ограничение превышено: %0/%1 символов', text.length, demoSynthesisCharacterLimit)
                            : trlCount('Ограничение длины %0 символов', demoSynthesisCharacterLimit)
                        }
                    </div>
                </div>
                <div className='row row_col row_gap-10'>
                    <Dropdown
                        className='landing-recognition__model-selector'
                        options={voices}
                        optionLabel='name'
                        optionValue='id'
                        value={voiceId}
                        onChange={({value}: {value: string}) => {
                            setVoiceId(value)
                            setAudio(undefined)
                        }}
                        valueTemplate={renderSelectedVoice}
                        itemTemplate={renderVoiceOption}
                        disabled={!ready}
                    />
                    {languageOptions.length > 0 &&
                        <div className='row row_col row_gap-10'>
                            <Dropdown
                                className='landing-recognition__model-selector'
                                options={languageOptions}
                                value={language}
                                onChange={({value}: {value: string}) => {
                                    setLanguage(value)
                                    setAudio(undefined)
                                }}
                                disabled={!ready}
                            />
                        </div>
                    }
                    {selectedVoice?.emotions &&
                        <Dropdown
                            className='landing-recognition__model-selector'
                            options={emotionOptions}
                            value={emotionId}
                            onChange={({value}: {value: string}) => {
                                setEmotionId(value)
                                setAudio(undefined)
                            }}
                            disabled={!ready}
                        />
                    }
                    <Dropdown
                        className='landing-recognition__model-selector'
                        options={speeds}
                        value={speed}
                        onChange={({value}: {value: number}) => {
                            setSpeed(value)
                            setAudio(undefined)
                        }}
                        disabled={!ready}
                    />
                </div>
            </div>
            <div className='landing-recognition__footer row row_gap-4'>
                <Button
                    type='button'
                    className='landing-blue-button'
                    onClick={handleClick}
                    disabled={!ready || !text || exceededLimit}
                >
                    {getButtonText()}
                </Button>
                {!ready && <Progress className='landing-recognition__loader'/>}
            </div>
        </div>
    )
}
